import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Col, Container } from "reactstrap"
import { Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { getCollectionsDetails, updateCollection } from "store/actions"
import MetaTag from "components/Common/Meta-tag"
import Form from "./Form"

const UpdateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const { collectionsDetails, loading } = useSelector(state => ({
    loading: state.Collection.loading,
    collectionsDetails: state.Collection.collectionDetails,
    storeList: state.Stores.storeList,
  }))

  const [store, setStore] = useState()
  const [logoImagesPreview, setLogoImagesPreview] = useState([])
  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [selectedProductId, setSelectedProductId] = useState([])

  useEffect(() => {
    setStore(collectionsDetails?.store?._id)
  }, [collectionsDetails])

  function handleValidSubmit(values) {
    const updateCollectionData = {
      // store:store,
      primaryLang: values.primaryLang,
      secondaryLang: values.secondaryLang,
      logoImages: logoImagesPreview,
      bannerImages: bannerImagesPreview,
      products: selectedProductId,
    }

    dispatch(
      updateCollection(updateCollectionData, collectionsDetails?._id, history)
    )
  }

  const formOption = {
    logoImagesPreview,
    setLogoImagesPreview,
    setBannerImagesPreview,
    bannerImagesPreview,
    selectedProductId,
    setSelectedProductId,
    setStore,
  }

  useEffect(() => {
    dispatch(getCollectionsDetails(params?.id))
  }, [dispatch])

  useEffect(() => {
    setBannerImagesPreview(collectionsDetails?.bannerImages)
    setLogoImagesPreview(collectionsDetails?.logoImages)
  }, [collectionsDetails])

  return (
    <>
      <MetaTag title={"Update Collections"} />

      <div className="page-content">
        <Breadcrumbs
          title="Classifications"
          breadcrumbItem="Update Collections"
        />
        <Container fluid>
          <div className="container-fluid mb-5">
            <Row>
              <Col lg={12} md={12}>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Form
                    formOption={formOption}
                    updateData={collectionsDetails}
                  />

                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-customer"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateCollections
