import { AvField } from "availity-reactstrap-validation"
import React from "react"
import { Col, Label, Row } from "reactstrap"
import UseImageUploader from "components/imageUploader/useImageUploader"
import PropTypes from "prop-types"

function Form({ formOption, updateData }) {
  const types = ["main", "sub"]
  const {
    bannerImagesPreview,
    setBannerImagesPreview,
    bannerAppImagesPreview,
    setBannerAppImagesPreview,
  } = formOption

  return (
    <Row>
      <Col className="col-12 d-flex flex-wrap">
        <div className="col-4 mb-3 px-3">
          <AvField
            name="tag"
            label="Tag"
            type="text"
            value={updateData?.tag ?? ""}
          />
        </div>

        <div className="col-4 mb-4 px-3">
          <AvField
            name="priority"
            label="Priority"
            type="number"
            value={updateData?.priority ?? ""}
          />
        </div>
        <div className="col-4 mb-4 px-3">
          <AvField
            label="Type"
            type="select"
            name="type"
            value={updateData?.type ?? ""}
            errorMessage="Select a banner type"
            validate={{
              required: { value: true },
            }}
          >
            <option>Select a banner type</option>
            {types?.map((item, key) => (
              <option key={key} value={item}>
                {item}
              </option>
            ))}
          </AvField>
        </div>

        <div className="col-12 mb-4 px-3">
          <AvField
            name="link"
            label="Link"
            type="text"
            value={updateData?.link ?? ""}
          />
        </div>

        <Col md="12" className="mb-4">
          <UseImageUploader
            title="Web Image"
            imageSize={4}
            imagesPreview={bannerImagesPreview}
            setImagesPreview={setBannerImagesPreview}
          />
        </Col>
        <Col md="12">
          <UseImageUploader
            title="App Image"
            imageSize={4}
            imagesPreview={bannerAppImagesPreview}
            setImagesPreview={setBannerAppImagesPreview}
          />
        </Col>
      </Col>
    </Row>
  )
}

export default Form

Form.propTypes = {
  formOption: PropTypes.object,
  updateData: PropTypes.object,
}
