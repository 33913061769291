import React, { useState } from "react"
import { Row, Col, Spinner, Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MyPagination from "../../../components/Common/MyPagination"

import "../../../assets/scss/datatables.scss"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { updateOrderItem } from "store/actions"
import { Link } from "react-router-dom"
import { defaultImg } from "assets/images"
import PersonalizationPopup from "./PersonalizationPopup"

const OrderItemTable = ({ currency, isPdf }) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [isOpen, setIsOpen] = useState({ status: false, data: {} })

  const { orderDetails, loading } = useSelector(state => ({
    orderDetails: state.Orders.orderDetails,
    loading: state.Orders.loading,
  }))

  const total = orderDetails?.cartItems?.length

  //pagination
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  function handleValidSubmit(values) {
    const updateOrders = {
      orderId: orderDetails?._id,
      orderItemStatus: values.orderItemStatus,
      orderItemId: values.orderItemId,
      orderStatus: orderDetails?.orderStatus,
    }
    dispatch(updateOrderItem(updateOrders, history))
  }

  const orderStatus = [
    "Processing",
    "Shipped",
    "Delivered",
    "Cancelled",
    // "Cancel Processing",
    "Returned",
    // "Return Processing",
  ]

  const PreColumns = [
    {
      dataField: "imageData",
      text: "Image",
    },
    // {
    //   dataField: "name",
    //   text: "Name",
    // },
    {
      dataField: "secondaryLang.name",
      text: "Secondary Name",
    },
    {
      dataField: "orderItemStatus",
      text: "Status",
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Qty",
      sort: true,
    },
    {
      dataField: "total",
      text: "Total",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  if (isPdf) {
    PreColumns?.splice(-1, 1)
  }

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const handleOrderItemStatusClass = status => {
    switch (status) {
      case "Cancelled":
        return "secondary"
      case "Cancel Processing":
        return "danger"
      case "Returned":
        return "secondary"
      case "Return Processing":
        return "danger"
      default:
        return "info"
    }
  }
  const handleOrderItemStatus = status => {
    switch (status) {
      case "Cancelled":
      case "Returned":
      case "Delivered":
        return false
      default:
        return true
    }
  }

  const userOrderItemData = map(orderDetails?.orderItems, (item, index) => ({
    ...item,
    imageData: (
      <Link to={`/product/${item?.product}`}>
        <img
          className="rounded-circle header-profile-user"
          style={{ width: "70px", height: "70px" }}
          src={item?.imageUrl ? item?.imageUrl : defaultImg}
        />
      </Link>
    ),
    // name: (
    //   <p
    //     className="pb-0 mb-0"
    //     style={{ whiteSpace: "break-spaces", maxWidth: "140px" }}
    //   >
    //     {item?.itemCode} <br />
    //     {item?.primaryLang.name}{" "}
    //     {item?.hasVarients
    //       ? `(${item?.varientValue} ${item?.varientUnit})`
    //       : ""}
    //   </p>
    // ),
    orderItemStatus: (
      <Badge
        className={
          "px-2 py-2 badge-soft-" +
          `${handleOrderItemStatusClass(item?.orderItemStatus)}`
        }
      >
        {" "}
        {item?.orderItemStatus}{" "}
      </Badge>
    ),
    price: (
      <span>
        {currency} {item?.price?.toFixed(2)}{" "}
      </span>
    ),
    total: (
      <span>
        {currency} {(item?.price * item?.quantity)?.toFixed(2)}{" "}
      </span>
    ),
    action: (
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(v)
        }}
      >
        <div className="d-flex align-items-center">
          <AvField
            type="select"
            name="orderItemStatus"
            style={{
              fontSize: "12px",
              width: "100%",
            }}
            className="text-secondary newClass "
            value={item?.orderItemStatus}
          >
            {orderStatus?.map((status, key) => (
              <option key={key} value={status}>
                {status}
              </option>
            ))}
          </AvField>

          <AvField
            type="text"
            name="orderItemId"
            hidden
            className="text-secondary"
            value={item._id || ""}
          ></AvField>

          {handleOrderItemStatus(item?.orderItemStatus) && (
            <button
              className="btn btn-md btn-soft bg-success text-white p-0 px-2 mx-1 custom_up_btn"
              type="submit"
              style={{
                border: "1px solid whitesmoke",
              }}
            >
              <i className="bx bx-upload"></i>
            </button>
          )}
        </div>
      </AvForm>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "_id",
      order: "desc",
    },
  ]

  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // }

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  // const { SearchBar } = Search

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const expandRow = {
    renderer: item => (
      <>
        {item?.personalization?.text ||
        item?.personalization?.images?.length >= 1 ||
        item?.personalization?.color ? (
          <tr>
            <td colSpan={columns?.length} style={{ marginBottom: "20px" }}>
              <p
                className="pb-0 mb-0"
                style={{
                  whiteSpace: "break-spaces",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {item?.itemCode} - &nbsp;
                {item?.primaryLang.name}{" "}
                {item?.hasVarients
                  ? `(${item?.varientValue} ${item?.varientUnit})`
                  : ""}
                &nbsp;&nbsp;
                <Badge
                  className={`px-2 py-2 badge-soft-info font-size-11 hover-zoom-0.5`}
                  onClick={() =>
                    setIsOpen({ status: true, data: item?.personalization })
                  }
                >
                  View Personalization Details
                </Badge>
              </p>
            </td>
          </tr>
        ) : (
          <p style={{ fontSize: "10px" }} className="text-muted">
            Personalization information not found
          </p>
        )}
      </>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    defaultExpandedId: [
      userOrderItemData.length > 0 ? userOrderItemData[0]._id : null,
    ],
    defaultExpanded: [
      userOrderItemData.length > 0 ? userOrderItemData[0] : null,
    ],
  }

  return (
    <React.Fragment>
      <PersonalizationPopup
        show={isOpen?.status}
        data={isOpen?.data}
        onCloseClick={() => setIsOpen({ status: false, data: {} })}
      />
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={userOrderItemData}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col xl="12">
                        <div
                          className={`table-responsive ${isPdf && "pdfTable"}`}
                        >
                          <hr />
                          <BootstrapTable
                            defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            key={"_id"}
                            keyField="_id"
                            responsive
                            bordered={false}
                            striped={false}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                            expandRow={expandRow}
                          />
                        </div>
                      </Col>
                    </Row>
                    {!isPdf && pages > 1 && (
                      <MyPagination
                        totalPages={pages}
                        page={page}
                        setPage={setPage}
                      />
                    )}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrderItemTable

OrderItemTable.propTypes = {
  currency: PropTypes.any,
  isPdf: PropTypes.bool,
}
