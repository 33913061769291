export const sidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
  },
  {
    routeLink: "/reviews",
    icon: "bx bxs-award",
    title: "Reviews",
  },
  // {
  //   routeLink: "/blogs",
  //   icon: "bx bx-file",
  //   title: "Blogs",
  // },
  {
    routeLink: "/stockUpdate",
    icon: "bx bx-store-alt",
    title: "Stock & Offer",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Site settings",
    isSeparator: true,
  },
  {
    routeLink: "/classifications",
    icon: "bx bxs-collection",
    title: "Categories",
  },
  {
    routeLink: "/collections",
    icon: "bx bxs-extension",
    title: "Collections",
  },
  {
    routeLink: "/groups",
    icon: "fas fa-layer-group",
    title: "Groups",
  },
  {
    routeLink: "/pages/all",
    icon: "bx bx-food-menu",
    title: "Pages",
  },
  {
    routeLink: "/banners",
    icon: "bx bxs-image",
    title: "Banners",
  },

  {
    routeLink: "/coupons",
    icon: "bx bxs-coupon",
    title: "Coupon",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
  },
  {
    routeLink: "/performance/product",
    icon: "bx bxs-rocket",
    title: "Performance",
  },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
]
