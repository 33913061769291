import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_CATEGORIES,
  CREATE_CATEGORY,
  GET_CATEGORY_DETAILS,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "./actionTypes"
import {
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryDetailsSuccess,
  getCategoryDetailsFail,
  createCategoryFail,
  createCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
} from "./actions"

import { post, get, ApiPut, del } from "../../helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function categoryApi({ page, limit, searchText }) {
  return get(
    `/category/admin/all?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getCategoryDetailsAPi = categoryId => {
  return get(`/category/admin/${categoryId}`)
}

function createCategoryApi({ category }) {
  return post("/category/admin/new", category)
}

function updateCategoryApi({ category, categoryId }) {
  return ApiPut(`/category/admin/${categoryId}`, category)
}

function deleteCategoryApi(categoryId) {
  return del(`/category/admin/${categoryId}`)
}

function* fetchCategories({ payload }) {
  try {
    const response = yield call(categoryApi, payload)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategoryDetails({ payload: categoryId }) {
  try {
    const response = yield call(getCategoryDetailsAPi, categoryId)
    yield put(getCategoryDetailsSuccess(response))
  } catch (error) {
    yield put(getCategoryDetailsFail(error))
  }
}

function* onCreateCategory({ payload }) {
  try {
    const response = yield call(createCategoryApi, payload)
    yield put(createCategorySuccess(response))
    Notification({
      type: "success",
      message: "Category Created Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createCategoryFail(error))
  }
}

function* onUpdateCategory({ payload }) {
  try {
    const response = yield call(updateCategoryApi, payload)
    yield put(updateCategorySuccess(response))
    Notification({
      type: "success",
      message: "Category Updated Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateCategoryFail(error))
  }
}

function* onDeleteCategory({ categoryId, history }) {
  try {
    const response = yield call(deleteCategoryApi, categoryId)
    yield put(deleteCategorySuccess(response))
    Notification({
      type: "success",
      message: "Category Deleted Successfully!",
      title: "",
    })
    history.goBack()
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}

function* prodCategorySaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORY_DETAILS, fetchCategoryDetails)
  yield takeEvery(CREATE_CATEGORY, onCreateCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
}

export default prodCategorySaga
