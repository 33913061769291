import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { MetaTags } from "react-meta-tags"

function MetaTag({ title, description, image }) {
  const descriptionLess170 = description?.substr(0, 170)

  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  return (
    <>
      <MetaTags>
        <title>
          {settings?.company?.name || "KlayBox"}{" "}
          {settings?.company?.name && "|"} {title}
        </title>
        <meta name="description" content={descriptionLess170} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="2182" />
        <meta property="og:image:height" content="541" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta property="og:type" content="website" />
        <meta name="keywords" content={title} />
      </MetaTags>
    </>
  )
}

export default MetaTag

MetaTag.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.any,
}
