import React from "react"
import PropTypes from "prop-types"
import { CardTitle, Modal, ModalBody } from "reactstrap"
import { useCopyText } from "hooks/useCopyText"

const PersonalizationPopup = ({ onCloseClick, show, data }) => {
  console.log(data)

  const { copyText } = useCopyText()

  const handleCopy = text => {
    copyText(text)
  }

  const imageUrls = data?.images?.map(i => i?.url)

  // const downloadImages = () => {
  //   Promise.all(
  //     imageUrls.map(url =>
  //       fetch(url)
  //         .then(response => response.blob())
  //         .then(blob => {
  //           const imageUrl = window.URL.createObjectURL(new Blob([blob]))

  //           const link = document.createElement("a")
  //           link.href = imageUrl
  //           link.setAttribute("download", `image_${Date.now()}.jpg`)

  //           document.body.appendChild(link)
  //           link.click()

  //           link.parentNode.removeChild(link)
  //         })
  //         .catch(error => {
  //           console.error("Error downloading image:", error)
  //         })
  //     )
  //   )
  // }

  const downloadImages = () => {
    Promise.all(
      imageUrls.map(url =>
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a URL for the blob
            const imageUrl = window.URL.createObjectURL(blob)

            // Create a link element
            const link = document.createElement("a")
            link.href = imageUrl
            link.setAttribute("download", `image_${Date.now()}.jpg`)

            // Append the link to the body, trigger a click, and remove the link
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            // Revoke the object URL to free memory
            window.URL.revokeObjectURL(imageUrl)
          })
          .catch(error => {
            console.error("Error downloading image:", error)
          })
      )
    )
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={deleteModalStyle}
      className="delete-modal"
    >
      <ModalBody style={deleteModalBodyStyle} className="">
        <CardTitle>Personalization Details</CardTitle>
        <div className="text-start">
          <div className="mb-3">
            <h6>Image :</h6>
            <div className="d-flex align-items-center flex-wrap ">
              {data?.images?.map((item, key) => (
                <img
                  key={key}
                  src={item?.url}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "contain",
                    marginRight: 10,
                  }}
                  alt="delete-image"
                />
              ))}
            </div>
          </div>
          <h6>Text :</h6>
          <p style={{ marginLeft: 15 }}>
            {data?.text}
            {data?.text && (
              <i
                onClick={() => handleCopy(data?.text)}
                className="bx bx-copy mx-2 "
                style={{ cursor: "copy" }}
                title="Copy url link"
              ></i>
            )}
          </p>
          <h6>Color :</h6>
          <p style={{ marginLeft: 15 }}>
            {data?.color || "---"}
            {data?.color && (
              <i
                onClick={() => handleCopy(data?.color)}
                className="bx bx-copy mx-2 "
                style={{ cursor: "copy" }}
                title="Copy url link"
              ></i>
            )}
          </p>
        </div>

        <div className="d-flex justify-content-end align-items-center mt-4 px-2">
          <button
            className="btn btn-sm btn-outline-dark w-fit me-3"
            style={{ borderRadius: 10, paddingLeft: 25, paddingRight: 25 }}
            onClick={onCloseClick}
          >
            Close
          </button>
          {data?.images?.length >= 1 && (
            <button
              style={{ borderRadius: 10 }}
              className="btn btn-sm btn-info w-100"
              onClick={downloadImages}
            >
              Download Images
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

PersonalizationPopup.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  data: PropTypes.any,
}

export default PersonalizationPopup

const deleteModalStyle = {
  width: 400,
}
const deleteModalBodyStyle = {
  minHeight: 300,
}
